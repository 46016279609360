// MEDIA QUERIES w. Material UI Breakpoints

// Breakpoints:
// sm: 600
// md: 960
// lg: 1280
// xl: 1920

.text-center {
	text-align: center;
}

@media (min-width: 600px) {
	.text-sm-left {
		text-align: left;
	}
	.text-sm-center {
		text-align: center;
	}
	.text-sm-right {
		text-align: right;
	}
}

@media (min-width: 960px) {
	.text-md-left {
		text-align: left;
	}
	.text-md-center {
		text-align: center;
	}
	.text-md-right {
		text-align: right;
	}
}

@media (min-width: 1280px) {
	.text-lg-center {
		text-align: left;
	}
	.text-lg-center {
		text-align: center;
	}
	.text-lg-right {
		text-align: right;
	}
}

@media (min-width: 1920px) {
	.text-xl-center {
		text-align: left;
	}
	.text-xl-center {
		text-align: center;
	}
	.text-xl-right {
		text-align: right;
	}
}
