@use "sass:math";

.navbar-new {
	nav {
		ul,
		li {
			list-style: none;
			padding: 0;
			margin: 0;
		}
		a {
			display: block;
			text-decoration: none;
			&:hover,
			&:visited {
				text-decoration: none;
			}
		}
	}

	.menu-bar {
		background: none;
		display: flex;
	}

	.menu-link {
		padding: 10px 25px;
		background: white;
		color: #505e5f;
		transition:
			background 0.2s,
			color 0.2s;
		position: relative;
		z-index: 1;
		&[aria-haspopup="true"] {
			padding-right: 40px;
		}
	}

	.mega-menu {
		background: none;
		z-index: 10;
		border-bottom-left-radius: 9;
	}

	.mega-menu--multiLevel {
		flex-direction: column;
	}

	// ------------------ MEDIA QUERIES

	@media all and (min-width: 950px) {
		// Desktop only

		.nav {
			background: none;
			> nav {
				margin: 0 auto;
			}
		}

		.menu-bar {
			position: relative;
			> li {
				> [aria-haspopup="true"] {
					// FUNCTIONALITY: Open mega menu

					&:hover {
						~ ul {
							display: flex;
							transform-origin: top;
							animation: dropdown 0.2s ease-out;
						}
					}
					// FUNCTIONALITY: Keep mega menu open
					~ ul {
						&:hover {
							display: flex;
						}
					}
				}

				// FUNCTIONALITY: Keep mega menu open
				&:focus-within {
					> [aria-haspopup="true"] {
						~ ul {
							display: flex;
						}
					}
				}
			}
		}

		.mega-menu {
			// LAYOUT: Mega menu
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;

			// FUNCTIONALITY: Keep mega menu open
			&:hover {
				display: flex;
			}
			a {
				color: #505e5f;
				&:hover {
					background: tint(#177e89, 85%);
					color: #505e5f;
				}
			}
		}

		.mega-menu--multiLevel {
			// LAYOUT: Multi level columns
			> li {
				width: math.div(60, 3) + 0%;
				border-left: 1px solid #e7eff6;
				box-shadow: none;

				> [aria-haspopup="true"] {
					~ ul {
						left: math.div(60, 3) + 0%;
						width: math.div(80, 3) + 0%;

						ul {
							width: 100%;
							left: 100%;
						}
					}
				}
			}

			li {
				// FUNCTIONALITY: Opening flyouts
				&:hover {
					> [aria-haspopup="true"] {
						~ ul {
							display: block;
							transform-origin: left;
							animation: flyout 0.2s ease-out;
						}
					}
				}

				// FUNCTIONALITY: Keeping flyouts open
				&:focus-within {
					> [aria-haspopup="true"] {
						~ ul {
							display: block;
						}
					}
				}

				// STYLING: Flyout link active states
				&:hover,
				&:focus-within {
					> [aria-haspopup="true"],
					> a {
						background: #e7eff6;
						color: #505e5f;
					}
				}
			}

			[aria-haspopup="true"] {
				~ ul,
				& {
					// FUNCTIONALITY: Keeping flyouts open
					&:hover {
						display: block;
					}
				}
				// LAYOUT: Flyouts
				~ ul {
					background: white;
					position: absolute;
					top: 0;
					min-height: 670px;

					box-shadow: none;
					border-bottom-right-radius: 9;
					border-bottom-left-radius: 9;
					border: 1px solid #e7eff6;
					z-index: 12;
				}
			}
		}
	}

	// ------------------ ANIMATIONS

	@keyframes dropdown {
		0% {
			opacity: 0;
			transform: scaleY(0);
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scaleY(1);
		}
	}

	@keyframes flyout {
		0% {
			opacity: 0;
			transform: scaleX(0);
		}
		100% {
			opacity: 1;
			transform: scaleX(1);
		}
	}
}
