// Only global styles should go here!
@import "./mediaQueries.scss";
@import "./login.scss";
@import "./navigation-desktop.scss";
@import "./slider.scss";

body,
html {
	margin: 0;
	font-family: "Mulish", sans-serif;
	line-height: "1.4";
	font-weight: 300;
	font-size: 1rem;
	scroll-behavior: smooth;
	letter-spacing: 0.015em;
	min-height: 100%;
}

// ========== fonts ==========
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	color: "#263739";
}
@media (max-width: 991px) {
	.h1,
	h1 {
		font-size: 1.4rem;
		color: "#263739";
	}
}

a {
	color: #168db5;
}

.p,
p {
	line-height: 1.5;
	font-family: "Mulish", sans-serif;
	font-weight: 300;
	color: #505e5f;
}

div,
.div {
	line-height: 1.5;
	font-family: "Mulish", sans-serif;
	font-weight: 300;
	color: "#263739";
}

.bold {
	font-weight: 700;
}

.small {
	font-size: 80%;
}

.light {
	font-weight: "200";
}

.uppercase {
	text-transform: uppercase;
}

.link {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.scrollX-no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Remove controls from Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

// hide default increase decrease arrows in input fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

input[type="text"],
input[type="number"],
textarea {
	font-size: 16px;
}

[id^="trustbadge-container"] {
	z-index: 999 !important;
}

.sc-fzozJi {
	color: rgba(0, 0, 0, 0.2) !important;
}

.sanity-page {
	display: flex;
	flex-direction: row;

	@media (min-width: 600px) {
		.width-50 {
			width: 50%;
		}
	}
	@media (max-width: 600px) {
		.width-50 {
			width: 100%;
		}
	}
}

.sentry-error-embed-wrapper {
	z-index: 9999 !important;
}
